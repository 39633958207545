<template>
  <v-row class="px-2 py-5">
    <v-col cols="12">
      <memberCounterCardVue v-if="indicators.hasOwnProperty('active')" :indicatorsContent="indicators" />
    </v-col>

    <v-col cols="12">
      <v-row class="mt-5 px-5 mr-0" justify="space-between" align="center">
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 3">
          <div class="searchbar d-flex flex-row my-4 align-center">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" fab small elevation="0" :loading="searchBarLoader"
                  class="rounded-pill rounded-r-0 search-prepend" color="#1b273e"
                  @click="filter(), (eventsSearchData.name = '')">
                  <v-icon size="20" color="white">mdi-magnify</v-icon></v-btn>
              </template>
              Click para buscar un miembro
            </v-tooltip>
            <v-text-field solo dense hide-details="auto" type="text" placeholder="Buscar miembro"
              class="busqueda rounded-pill rounded-l-0" v-model="eventsSearchData.name"
              v-on:keypress.enter="filter()" />
          </div>
        </v-col>

        <v-col :cols="$vuetify.breakpoint.smAndDown ? 6 : 3" :class="$vuetify.breakpoint.smAndUp || 'pa-0'">
          <v-select :items="items_validate_status" hide-details="auto" solo dense clearable placeholder=" Estatus"
            v-model="eventsSearchData.validationStatus" @change="filter()" />
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 6 : 3" :class="$vuetify.breakpoint.smAndUp || 'pa-0'">
          <v-select :items="items_roles" hide-details="auto" solo dense clearable placeholder=" Tipo de membresía"
            v-model="eventsSearchData.role" @change="filter()" />
        </v-col>

        <v-menu :close-on-content-click="false" v-model="filtersMenu">
          <template v-slot:activator="{ on, attrs }">
            <v-badge bordered color="#1b273e" overlap :content="modifiedFilterFields.length || ''"
              :value="modifiedFilterFields.length > 0">
              <v-btn color="#1b273e" dark v-bind="attrs" v-on="on">
                <v-icon>mdi-filter-variant</v-icon>
                Filtrar

                <template v-if="modifiedFilterFields.length">
                  <v-btn icon @click.stop="resetFilters()">
                    <v-icon color="white">mdi-close-circle-outline</v-icon>
                  </v-btn>
                </template>
              </v-btn>
            </v-badge>
          </template>

          <v-card elevation="4" class="filters-card">
            <v-card-title>
              <v-row justify="space-between" class="ma-2">
                <span class="wc-subtitle-1">Filtrar por</span>

                <v-btn color="#1b273e" class="white--text" @click="applyFilters()">
                  <span class="wc-caption">Aplicar filtros</span>
                </v-btn>
              </v-row>
            </v-card-title>

            <v-row class="ma-1" justify="center">
              <v-col cols="10">
                <div class="searchbar d-flex flex-row my-4 align-center">
                  <v-btn fab small elevation="0" class="rounded-pill rounded-r-0 search-prepend" color="#1b273e">
                    <v-icon size="20" color="white">mdi-magnify</v-icon></v-btn>
                  <v-text-field solo dense hide-details="auto" type="text" placeholder="Buscar filtro"
                    class="busqueda rounded-pill rounded-l-0" v-model="filterSearch" />
                </div>
              </v-col>
            </v-row>

            <v-row class="mx-4">
              <v-expansion-panels class="expansion-panel-width">
                <v-expansion-panel v-for="(field, index) in filterFieldsComputed" :key="index">
                  <v-expansion-panel-header>
                    {{ field.name }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row class="ma-0">
                      <v-col cols="12" class="py-1">
                        <v-text-field v-if="field.fieldType == 'TEXT'" :label="field.name" v-model="field.value" filled
                          dense hide-details="auto" @change="
        modifiedFilterFields.push(field.formattedName)
        " />
                        <v-select v-if="field.fieldType === 'LIST'" v-model="field.value" :items="field.options"
                          :label="field.name" class="higher-z-index" @change="
        modifiedFilterFields.push(field.formattedName)
        " />
                        <v-text-field v-if="field.fieldType == 'NUMBER'" v-model="field.value" :label="field.name"
                          filled dense type="number" @change="
        modifiedFilterFields.push(field.formattedName)
        ">
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-card>
        </v-menu>
      </v-row>
    </v-col>
    <v-row class="ma-0 pa-4" justify="end">
      <MemberForm create @update="reloadInfo" colorProp="#1b273e" textColor="white" />
    </v-row>

    <v-row class="mx-2 displayW" justify="end">
      <v-pagination v-model="eventsSearchData.page" :length="membersPaginated.totalPages || 1"
        @input="filtrarMiembros()" total-visible="10" style="width: 100%" class="my-2">
      </v-pagination>

      <div class="">
        <span class="wc-caption total-text">
          {{ membersPaginated.totalDocs || 0 }} resultados
        </span>
      </div>
    </v-row>

    <v-row class="displayW">
      <v-card class="displayW2">
        <v-expansion-panels accordion>
          <v-expansion-panel v-for="(member, index) of membersNewComputed" :key="index"
            :append-icon="$vuetify.breakpoint.smAndDown ? '' : '$expand'" style="border: 1px solid lightgray">
            <v-expansion-panel-header>
              <v-row>
                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 8">
                  <v-row class="ma-0">
                    <v-col cols="auto">
                      <v-chip :color="homologateStatusWithColor(member.validationStatus)
          .color
        " class="rounded-lg font-weight-bold">
                        {{
        homologateStatusWithColor(member.validationStatus)
          .status
      }}
                      </v-chip>
                    </v-col>
                    <v-row>
                      <v-col cols="auto" class="d-flex align-center">
                        <span class="ma-0" style="font-weight: 800; text-transform: uppercase">
                          {{ member.name || "N/D" }} -
                          {{ member.firstLastname || "N/D" }}
                        </span>
                      </v-col>
                      <v-col cols="auto" class="d-flex align-center">
                        <p class="ma-0">
                          <span class="textclass"> </span>
                          <span v-if="ampiIds.includes(member.headOffice)
        ">
                            <span v-for="i in 5" :key="i">
                              <v-icon :class="starClass(i, member)">mdi-star</v-icon>
                            </span>
                          </span>
                        </p>
                      </v-col>
                    </v-row>
                    <v-row class="pl-5">
                      <v-col cols="auto" class="d-flex align-center">
                        <p class="ma-0">
                          <span class="textclass">RAZÓN SOCIAL - </span>
                          {{ member.company || "N/D" }}
                        </p>
                      </v-col>
                      <v-col cols="auto" class="d-flex align-center">
                        <p class="ma-0">
                          <span class="textclass">E-MAIL - </span>{{ member.email }}
                        </p>
                      </v-col>
                      <v-col cols="auto" class="d-flex align-center" v-if="user.hierarchyType == 'TWO_LEVELS'">
                        <p class="ma-0">
                          <span class="textclass">CAPÍTULO - </span>{{
        member.branch
          ? member.branch.company || "N/D"
          : "N/D"
      }}
                        </p>
                      </v-col>
                      <v-col v-if="member.hasOwnProperty('memberOf')" cols="auto" class="d-flex align-center">
                        <p class="ma-0">
                          <span class="textclass">MEMBRESÍA - </span>
                          {{ member.memberOf.affiliation || "N/D" }}
                        </p>
                      </v-col>

                      <v-col v-if="member.validationStatus != 'DESARROLLADOR'" cols="auto" class="d-flex align-center">
                        <p class="ma-0">
                          <span class="textclass">NIVEL DE MEMBRESÍA - </span>
                          {{ setRole(member.role) }}
                        </p>
                      </v-col>

                      <v-col cols="auto" class="d-flex align-center" v-if="member.hasOwnProperty('memberOf')">
                        <p class="ma-0">
                          <span class="textclass">TIPO DE MEMBRESÍA - </span>
                          {{ member.memberOf.affiliationType || "N/D" }}
                        </p>
                      </v-col>

                      <v-col cols="auto" class="d-flex align-center" v-if="member.validationStatus == 'ACTIVE' && !loadingData
        ">
                        <p class="ma-0">
                          <span class="textclass">FECHA DE EXPIRACIÓN - </span>

                          {{ member.expirationDate }}
                        </p>
                      </v-col>

                      <v-col cols="auto" class="d-flex align-center" v-if="member.role == 'MEMBERSHIP_USER'">
                        <p class="ma-0">
                          <span class="textclass">MIEMBRO TITULAR - </span>
                          {{
        member.membership
          ? member.membership.email || "N/D"
          : "N/D"
      }}
                        </p>
                      </v-col>

                      <v-col cols="auto" class="d-flex align-center" v-if="['MEMBER', 'MEMBERSHIP_USER'].includes(member.role) &&
        member.headOffice &&
        ampiIds.includes(member.headOffice) &&
        member.validationStatus != 'ACTIVE'
        ">
                        <p v-if="member.validationStatus != 'DESARROLLADOR'" class="ma-0 font-weight-bold">
                          <span class="textclass">PAGOS - </span>
                          <v-chip class="mx-1" :color="member.payments
          ? member.payments.BRANCH
            ? 'green lighten-1'
            : ''
          : ''
        ">
                            Local
                          </v-chip>
                          <v-chip class="mx-1" :color="member.payments
          ? member.payments.HEAD_OFFICE
            ? 'green lighten-1'
            : ''
          : ''
        ">
                            Nacional
                          </v-chip>
                        </p>
                      </v-col>
                    </v-row>
                  </v-row>
                </v-col>

                <!-- cuadro para visualizar o descargar documentos 8 4-->
                <v-col :cols="$vuetify.breakpoint.smAndDown ? 9 : 2" class="d-flex align-center flex-row-reverse">
                  <v-dialog transition="dialog-bottom-transition" width="700" style="border-radius: 0px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="#b3e5fc" class="text-capitalize rounded-pill"
                        style="font-size: 17px; font-weight: 800" v-bind="attrs" v-on="on">
                        <v-icon class="mx-2"> mdi-folder-outline</v-icon>
                        Documentos
                      </v-btn>
                    </template>
                    <template v-slot:default="dialog">
                      <v-card :class="$vuetify.breakpoint.smAndDown ? 'pa-9' : 'pa-14'
        " style="border-radius: 0px" color="white">
                        <v-row class="mb-5">
                          <v-col cols="auto" class="py-2">
                            <v-chip :color="homologateStatusWithColor(
        member.validationStatus
      ).color
        " class="rounded-lg font-weight-bold">
                              {{
        homologateStatusWithColor(
          member.validationStatus
        ).status
      }}
                            </v-chip>
                          </v-col>
                          <v-col cols="auto" class="d-flex align-center py-2">
                            <span class="ma-0" style="
                                font-weight: 800;
                                text-transform: uppercase;
                              ">{{ member.name || "N/D" }} -
                              {{ member.lastName || "N/D" }}</span>
                          </v-col>
                          <v-col cols="auto" class="d-flex align-center py-2">
                            <p class="ma-0">
                              <span class="textclass">E-MAIL - </span>{{ member.email || "N/D" }}
                            </p>
                          </v-col>
                          <v-col cols="auto" class="d-flex align-center py-2" v-if="user.hierarchyType == 'TWO_LEVELS'">
                            <p class="ma-0">
                              <span class="textclass">CAPÍTULO - </span>{{
        member.branch
          ? member.branch.company || "N/D"
          : "N/D"
      }}
                            </p>
                          </v-col>
                        </v-row>
                        <v-card-text class="px-0">
                          <span style="
                              color: #35404c;
                              font-weight: 900;
                              font-size: 18px;
                            ">Lista de documentos</span>
                          <v-divider class="mt-1" style="
                              background: #35404c;
                              border: 1px solid #35404c;
                            " />
                          <v-list>
                            <v-list-item-group>
                              <v-list-item dense v-for="doc in member.docs" :key="doc.formattedName">
                                <v-list-item-content>
                                  <span style="
                                      font-size: 15px;
                                      color: #35404c;
                                      font-weight: 800;
                                    ">{{ doc.name }}</span>
                                </v-list-item-content>
                                <v-list-item-action class="d-flex flex-row justify-space-between">
                                  <v-dialog fullscreen hide-overlay transition="dialog-bottom-transition">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn v-bind="attrs" v-on="on" :disabled="doc.value == null ||
        doc.value == undefined ||
        doc.value == ''
        " small fab elevation="0" class="mr-2 rounded-pill">
                                        <v-icon>mdi-eye-outline</v-icon>
                                      </v-btn>
                                    </template>
                                    <template v-slot:default="dialog">
                                      <v-btn v-if="$vuetify.breakpoint.smAndDown != true
        " @click="dialog.value = false" bottom right absolute fab color="accent" style="
                                          position: absolute;
                                          bottom: 50px;
                                          right: 50px;
                                        "><v-icon>mdi-close</v-icon></v-btn>
                                      <v-btn v-else @click="dialog.value = false" large
                                        bottom><v-icon>mdi-close</v-icon></v-btn>
                                      <iframe v-if="doc.value.includes('.pdf')" type="application/pdf" :src="'https://drive.google.com/viewerng/viewer?embedded=true&url=' +
        doc.value
        " style="width: 100%; height: 100%"></iframe>
                                      <v-card v-else width="20%" height="20%"
                                        class="d-flex align-center justify-center">
                                        <v-img :src="doc.value" width="auto" height="auto" max-height="90%"
                                          max-width="90%" />
                                      </v-card>
                                    </template>
                                  </v-dialog>

                                  <v-btn :disabled="doc.value == null ||
        doc.value == undefined ||
        doc.value == ''
        " :href="doc.value" fab small class="rounded-pill" elevation="0">
                                    <v-icon> mdi-download-outline </v-icon>
                                  </v-btn>
                                </v-list-item-action>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end">
                          <v-btn @click="dialog.value = false" elevation="0" style="font-size: 18px; font-weight: 800"
                            class="text-capitalize rounded-pill">
                            <v-icon color="#35404c">mdi-chevron-left</v-icon>
                            <span style="color: #35404c" class="pr-2">Volver</span>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-col>

                <!-- Editar información del miembro -->
                <v-col :cols="$vuetify.breakpoint.smAndDown ? 3 : 'auto'" class="d-flex justify-center align-center"
                  v-if="user.permissions.Membership &&
        user.permissions.Membership.update == true
        ">
                  <!-- @click="editItem(item)"           @click="getform()" -->

                  <MemberForm edit :member="member" :profilePicture="member.profilePicture" @update="reloadInfo" />
                </v-col>
                <!-- user.permissions.Membership &&
                user.permissions.Membership.update == true -->

                <v-col :cols="$vuetify.breakpoint.smAndDown ? 3 : 'auto'" class="d-flex justify-center align-center"
                  v-if="user.role === 'HEAD_OFFICE' ||
        user.role === 'ORGANIZATION' ||
        user.role === 'ORGANIZATION_USER'
        ">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-on="on" v-bind="attrs" @click.stop="openDeleteDialog(index)" class="rounded-xl"
                        elevation="1" style="background-color: rgb(255, 0, 0)" fab small>
                        <v-icon size="20" color="white"> mdi-delete </v-icon>
                      </v-btn>
                    </template>

                    <span>Eliminar miembro</span>
                  </v-tooltip>

                  <personalized-dialog-vue hidden :hide-name="!member.confirmDelete" hide-image :disableContinueButton="member.confirmDelete && member.authDelete === false
        " :closeDialogOnContinue="member.confirmDelete && member.authDelete
        " :dialog="member.deleteDialog" @save="deleteMemberUser(index)"
                    @discard="closeDeleteDialog(index)">
                    <template #alertName v-if="member.confirmDelete">
                      <p class="pa-0 ma-0 d-flex align-center justify-center">
                        <v-icon size="30" class="pr-4" color="red">
                          mdi-alert-outline
                        </v-icon>
                        Eliminar miembro
                      </p>
                    </template>
                    <template #alertContent>
                      <v-row v-if="!member.confirmDelete">
                        <v-col cols="2" class="d-flex align-center justify-center">
                          <v-icon size="40" color="red">
                            mdi-alert-outline
                          </v-icon>
                        </v-col>
                        <v-col>
                          <p class="title text-left" style="word-break: break-word">
                            Estás a punto de eliminar al miembro
                            <span style="color: black; font-weight: 600">{{
        `${member.name} ${member.firstLastname}`
      }}</span>
                            y su información no podrá ser recuperada, ¿deseas
                            continuar con este proceso?.
                          </p>
                        </v-col>
                      </v-row>
                      <v-row v-else>
                        <v-col cols="auto" class="d-flex align-center justify-center">
                          <v-checkbox color="#1b273e" v-model="member.authDelete" hide-details>
                            <template #label>
                              <p class="title text-left" style="word-break: break-word">
                                Estoy enterado de que el miembro
                                <span style="color: black; font-weight: 600">{{
        `${member.name} ${member.firstLastname}`
      }}</span>
                                no se podrá recuperar una vez se haya eliminado.
                              </p>
                            </template>
                          </v-checkbox>
                        </v-col>
                      </v-row>
                    </template>
                    <template #button>
                      {{ !member.confirmDelete ? "Continuar" : "Eliminar" }}
                    </template>
                  </personalized-dialog-vue>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="pa-2 pl-7">
                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4" class="py-1" v-for="field in member.fields"
                  :key="field.formattedName">
                  <p>
                    <span>
                      <strong>{{ field.name }}</strong><br />
                      {{ field.value || "N/D" }}
                    </span>
                  </p>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-row>
    <v-snackbar :multi-line="true" :top="true" :right="true" :timeout="time" v-model="isVisible" :color="color"
      style="z-index: 249" class="rounded-xl" transition="slide-x-reverse-transition">
      <span class="subtitle-2 font-weight-bold">{{ text }}</span>
    </v-snackbar>
  </v-row>
</template>

<script>
//
import { mapState, mapMutations, mapActions } from "vuex";
import { validations } from "@/share/validations.js";
import MemberForm from "@/components/shared/MemberForm";
import { homologateStatusWithColor } from "@/common/hook/useHelper.js";
import memberCounterCardVue from "../../../components/dashboard/event/analytics/member-counter-card.vue";
import PersonalizedDialogVue from "../../../components/shared/PersonalizedDialog.vue";
import axios from "axios";

export default {
  components: {
    PersonalizedDialogVue,
    memberCounterCardVue,
    MemberForm,
  },
  data() {
    return {
      loadingData: false,

      members: [],
      expirationDate: "",
      starRating: 0,
      filtersMenu: false,
      filterFields: [],
      modifiedFilterFields: [],
      filterSearch: "",
      membersNew: [],
      memberFields: [],
      isChapter: false,
      searchBarLoader: false,
      dialogMember: false,
      activator: null,
      attach: null,
      editing: null,
      showPass: false,
      editingIndex: -1,
      indicators: {},
      ampiIds: ['62b622c66a7441ae20159d7d', '6297b0fec7bf8b6f826313be'],
      item_labels_name: {
        company: "compañía",
        createdAt: "Fecha de creación",
        updatedAt: "Fecha de actualización",
        gender: "Género",
        maximum_degree_of_studies: "Grado máximo de estudios",
        pagina_web: "Web",
        correo_electronico_de_contacto: "Correo",
        fecha_de_cumpleanos: "Fecha de nacimiento",
        codigo_postal: "Código postal",
        numero_de_telefono: "Teléfono fijo",
        numero_de_celular: "Teléfono móvil",
        memberFrom: "Miembro desde",
      },
      drawer_fields_excluded: [
        "ine",
        "comprobanteDomicilio",
        "companyImage",
        "localidad",
        "constanciaSituacionFiscal",
        "firstLastname",
        "memberForm",
        "createdAt",
        "validationStatus",
      ],
      items_validate_status: [
        { text: "Prospecto", value: "PROSPECTO" },
        { text: "Activo", value: "ACTIVE" },
        { text: "Inactivo", value: "INACTIVO" },
        { text: "Expirado", value: "EXPIRED" },
      ],
      items_roles: [
        { text: "Asociado", value: "MEMBER" },
        { text: "Afiliado", value: "MEMBERSHIP_USER" },
      ],
      region: [
        {
          text: "Norte",
        },
        {
          text: "Centro",
        },
        {
          text: "Sur",
        },
      ],
      headers: [
        { text: "", value: "checkbox", sortable: false },
        { text: "", value: "validationStatus", sortable: false },
        { text: "", value: "name", sortable: false },
        {
          text: " ",
          align: "start",
          value: "email",
          sortable: false,
        },
        { text: "", value: "company", sortable: false },
        //{ text: " ", value: "region", sortable: false },
        { text: " ", value: "branch", sortable: false },
        { text: " ", value: "actions", sortable: false },
      ],
      memberBranch: "",
      branches: [],
      nonce: 1,
      menu: false,
      model: [
        {
          text: "AMPI - Guanajuato ",
        },
      ],
      nameRules: validations.nameValidation({ name: "nombre", required: true }),
      companyRules: validations.nameValidation({
        name: "company",
        required: true,
      }),
      firstLastnameRules: validations.nameValidation({
        name: "primer apellido",
        required: true,
      }),
      emailRules: validations.emailValidation(),
      passwordRules: validations.generalFValidation({
        required: true,
        minLength: 8,
        name: "contraseña",
      }),
      memberData: {
        id: "",
        name: "",
        firstLastname: "",
        email: "",
        company: "",
        region: "",
        branch: "",
      },
      memberDocuments: [],
      boolEdit: false,
      x: 0,
      search: null,
      y: 0,
      eventsSearchData: {
        name: "",
        branch: "",
        role: "",
        page: 1,
        perPage: 10,
        validationStatus: "",
      },
      AsociateInfo: {
        id: "",
        name: "",
        firstLastname: "",
        email: "",
        company: "",
        region: "",
        branch: "",
      },
      dialogDelete: false,
      alertBool: false,
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    model(val, prev) {
      if (val.length === prev.length) return;

      this.model = val.map((v) => {
        if (typeof v === "string") {
          v = {
            text: v,
            color: this.colors[this.nonce - 1],
          };

          this.items.push(v);

          this.nonce++;
        }

        return v;
      });
    },
  },
  computed: {
    ...mapState("loader", ["loaderVisible"]),
    ...mapState("user", ["user"]),
    ...mapState("members", ["members", "membersPaginated"]),
    ...mapState("notification", ["text", "color", "time", "visible"]),

    filterFieldsComputed() {
      if (!this.filterSearch) {
        return this.filterFields;
      } else {
        return this.filterFields.filter((field) => {
          //no tiene en cuenta los signos de puntuación ni acentos
          let name = field.name
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase();
          let search = this.filterSearch
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase();
          return name.includes(search);
        });
      }
    },

    isVisible: {
      get() {
        return this.visible;
      },
      set(value) {
        this.hide();
      },
    },

    membersNewComputed() {
      return this.membersNew;
    },
  },
  methods: {
    ...mapMutations("loader", ["loading", "loaded"]),
    ...mapMutations("notification", ["show", "hide"]),
    ...mapMutations("members", ["clearmembersPaginated"]),
    ...mapActions("members", [
      "getMembers",
      "createMember",
      "getMemberIndicators",
      "getChapterIndicators",
      "getBranchMembersPaginated",
      "updateMember",
      "deleteMember",
    ]),
    ...mapActions("branches", [
      "getBranches",
      "createBranchMember",
      "updateBranchMember",
    ]),
    ...mapActions("registrationFields", ["getRegistrationFields"]),
    homologateStatusWithColor,
    starClass(index, member) {
      return {
        "text-yellow": true,
        "mdi-star": index <= member.starRating,
        "mdi-star-outline": index > member.starRating,
      };
    },
    resetFilters() {
      this.filterSearch = "";
      this.modifiedFilterFields = [];
      for (let field of this.filterFields) {
        field.value = "";
      }
      this.eventsSearchData.filters = [];
      this.filter();
    },

    applyFilters() {
      this.filtersMenu = false;
      let formattedFilters = [];
      this.modifiedFilterFields.forEach((formattedName) => {
        let filter = this.filterFields.find(
          (field) => field.formattedName == formattedName
        );
        formattedFilters.push(filter);
      });
      this.eventsSearchData.filters = formattedFilters;
      this.filter();
    },

    filterValue(name, value) {
      const filter = {
        name: name,
        value: value,
      };
      return filter;
    },

    async initializeFields() {
      //get list of fields
      let fields;
      if (this.user.role == "BRANCH") {
        fields = await this.getRegistrationFields(this.user.headOffice._id);
      } else if (this.user.role == "ORGANIZATION_USER") {
        fields = await this.getRegistrationFields(this.user.organization);
      } else {
        fields = await this.getRegistrationFields(this.user._id);
      }
      this.memberFields = fields.data.data;
      //copy value instead of reference
      this.filterFields = JSON.parse(JSON.stringify(this.memberFields));
      this.filterFields = this.filterFields.filter(
        (field) => field.fieldType !== "DOC"
      );
    },
    formatMemberData(member) {
      let docs = [];
      let fields = [];

      if (!member.memberData) {
        member.memberData = [];
      }

      // if(!member.fiscalData){
      //   member.fiscalData = {
      //     legalName: '',
      //     taxId: '',
      //     street: '',
      //     zipCode: '',
      //     email: '',
      //     rf: ''
      //   }
      // }
      if (member.memberActiveItems && member.memberActiveItems.length > 0) {
        let activeItems = {}
        member.memberActiveItems.forEach((item) => {
          activeItems[item.type] = item.status
        })
        member.payments = activeItems
      }
      console.log(member.payments);

      if (this.memberFields) {
        for (let i = 0; i < this.memberFields.length; i++) {
          const fieldToPush = JSON.parse(JSON.stringify(this.memberFields[i]));
          fieldToPush.value = "";
          if (this.memberFields[i].fieldType == "DOC") {
            docs.push(fieldToPush);
          } else {
            fields.push(fieldToPush);
          }
          delete this.memberFields[i].organization;
        }
      }

      for (let i = 0; i < fields.length; i++) {
        for (let j = 0; j < member.memberData.length; j++) {
          if (fields[i].formattedName == member.memberData[j].formattedName) {
            fields[i].value = member.memberData[j].value;
          }
        }
      }

      for (let i = 0; i < docs.length; i++) {
        for (let j = 0; j < member.memberData.length; j++) {
          if (docs[i].formattedName == member.memberData[j].formattedName) {
            docs[i].value = member.memberData[j].value;
          }
        }
      }
      member.deleteDialog = false;
      member.confirmDelete = false;
      member.authDelete = false;
      member.docs = JSON.parse(JSON.stringify(docs));
      member.fields = JSON.parse(JSON.stringify(fields));
      /*
      if (member.hasOwnProperty('membership')) {
      let tempID = member.membership._id;

      let response =  this.getAsociateInfoById(tempID);


        this.AsociateInfo = Object.assign(this.AsociateInfo, response.data);
        console.log(this.AsociateInfo);

    }*/
      return member;
    },
    edit(index, item) {
      if (!this.editing) {
        this.editing = item;
        this.editingIndex = index;
      } else {
        this.editing = null;
        this.editingIndex = -1;
      }
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    nextPage() {
      this.eventsSearchData.page++;
      this.filter();
    },

    async fetchBranches() {
      const response = await this.getBranches();

      if (response.status === 200) {
        response.branches.forEach((br) => {
          this.branches.push({
            text: br.company,
            _id: br._id,
          });
        });
      }
    },

    async fetchIndicators() {
      let response = null;

      if (this.$route.params.chapterId) {
        response = await this.getChapterIndicators(
          this.$route.params.chapterId
        );
      } else {
        response = await this.getMemberIndicators();
      }

      if (response.status == true) {
        let data = response.data;
        this.indicators = {
          active: {
            color: "ACTIVE",
            members: data.activeMembers,
            submembers: data.activeSubMembers,
          },
          inactive: {
            color: "INACTIVO",
            members: data.inactiveMembers,
            submembers: data.inactiveSubMembers,
          },
          prospect: {
            color: "PROSPECTO",
            members: data.prospectMembers,
            submembers: data.prospectSubMembers,
          },
          expired: {
            color: "EXPIRED",
            members: data.expiredMembers,
            submembers: data.expiredSubMembers,
          },
        };
      }
    },
    async getExpirationDateForMember(userId) {
      try {
        const response = await this.axios({
          url: "users/expiration-date/" + userId,
          method: "GET",
        });
        return response.data.expirationDate;
      } catch (error) {
        return null;
      }
    },

    async getAsociateInfoById(tempID) {
      try {
        const response = await this.axios({
          url: "users/" + tempID,
          method: "GET",
        });

        //console.log(this.AsociateInfo.user.name);
        return { status: true, data: response.data };
      } catch (error) {
        console.log("Error en la petición:", error);
        return { status: false, message: error };
      }
    },

    async filter() {
      this.eventsSearchData.page = 1;
      await this.filtrarMiembros();
    },

    async filtrarMiembros() {
      this.loadingData = true;
      try {
        this.loading();
        this.searchBarLoader = true;
        if (this.isChapter) {
          this.eventsSearchData.branch = this.$route.params.chapterId;
        }
        this.clearmembersPaginated();
        const response = await this.getBranchMembersPaginated(
          this.eventsSearchData
        );
        if (response.status === true && this.membersPaginated.members) {
          this.membersNew = [];
          for (const m of this.membersPaginated.members) {
            // Usar bucle for...of
            let memberToPush = this.formatMemberData(m);
            if (
              this.user._id == "6297b0fec7bf8b6f826313be" ||
              this.user.headOffice == "6297b0fec7bf8b6f826313be"
            ) {
              memberToPush.starRating = await this.setStars(memberToPush);
            } else {
              memberToPush.starRating = 0;
            }
            this.membersNew.push(memberToPush);
          }
        } else {
          console.log("Error en la petición: " + response.message);
        }

        this.loaded();
        this.searchBarLoader = false;
        //console.log(this.membersNew);
        for (let i = 0; i < this.membersNew.length; i++) {
          const member = this.membersNew[i];
          if (member.validationStatus == "ACTIVE") {
            //console.log(member._id);
            let spiration = await this.getExpirationDateForMember(member._id);
            member.expirationDate = spiration;
          }

          if (
            this.ampiIds.includes(member.headOffice)
          ) {
            member.expirationDate = "1-01-2025";
          }

          if (member.hasOwnProperty("membership")) {
            let tempID = member.membership._id;

            let response = await this.getAsociateInfoById(tempID);

            this.AsociateInfo = Object.assign(this.AsociateInfo, response.data);
          }
        }
      } catch (error) {
        console.error("Error cargando fechas de expiración:", error);
      } finally {
        this.loadingData = false;
        this.loaded();
      }
    },

    async setStars(member) {
      let starRating = 0;
      member.starRating = 0;

      if (
        member.name != "" &&
        member.firstLastname != "" &&
        member.email != "" &&
        member.phone != undefined &&
        //member.memberInformation.codigo_postal != "N/D" &&
        member.memberData[0].value != "" &&
        //member.memberInformation.calle != "N/D" &&
        member.memberData[1].value != "" &&
        //member.memberInformation.num_ext != "N/D" &&
        member.memberData[2].value != "" &&
        //member.memberInformation.colonia != "N/D" &&
        member.memberData[4].value != "" &&
        //member.memberInformation.municipio != "N/D" &&
        member.memberData[5].value != "" &&
        //member.memberInformation.estado != "N/D"
        member.memberData[6].value != ""
      ) {
        member.starRating = member.starRating + 1;

        if (
          //member.memberInformation.fecha_de_cumpleanos != "N/D" &&
          member.memberData[9].value != "" &&
          //member.memberInformation.grado_maximo_de_estudios != "N/D" &&
          member.memberData[11].value != "" &&
          //member.memberInformation.gender != "N/D" &&
          member.memberData[10].value != "" &&
          //member.memberInformation.comprobanteDomicilio != "NOT_ASSIGNED"
          member.memberData[15].value != ""
        ) {
          member.starRating = member.starRating + 1;

          if (
            //member.memberInformation.curp != "N/D" &&
            member.memberData[7].value != "" &&
            // member.memberInformation.rfc != "N/D" &&
            member.memberData[8].value != "" &&
            //member.memberInformation.constanciaSituacionFiscal != "NOT_ASSIGNED" &&
            member.memberData[13].value != "" &&
            //member.memberInformation.ine != "NOT_ASSIGNED"
            member.memberData[14].value != ""
          ) {
            member.starRating = member.starRating + 1;

            if (
              //member.memberInformation.pagina_web != "N/D" //&&
              //member.memberInformation.companyImage != "N/D"

              member.memberData[16].value != "" &&
              member.memberData[16].value != null
            ) {
              member.starRating = member.starRating + 1;

              if (
                member.memberData[19].value != "" &&
                member.memberData[19].value != null
              ) {
                member.starRating = member.starRating + 1;
              }
            }
          }
        }
      }
      starRating = member.starRating;

      return starRating;
    },

    setBranch(v) {
      this.memberData.branch = {
        _id: v._id,
        company: v.text,
      };
    },

    setRole(role) {
      const itemRole = this.items_roles.find((e) => e.value == role);
      return itemRole.text;
    },

    async saveInfo(userData, id, status, updateFiles) {
      // this.loading();
      if (status == "update") {
        let up = {
          memberInformation: userData.memberInformation,
        };
        let response;
        if (this.user.role == "BRANCH") {
          response = await this.updateBranchMember({ userData: up, id: id });
        } else {
          response = await this.updateMember({ userData: up, id: id });
        }
        console.log(response.message);
        if (response.status === true) {
          this.loaded();
          this.show({
            text: "Miembro actualizado exitosamente!",
            color: "success",
            time: 3000,
          });
        }
        setTimeout(() => {
          this.filter();
        }, 2000);
      } else {
        // this.memberData.region = this.memberData.region.text;
        let response;
        if (this.user.role == "BRANCH") {
          response = await this.createBranchMember({
            memberData: userData,
            branchId: this.user._id,
          });
        } else {
          response = await this.createMember(userData);
        }
        console.log(response.message);
        if (response.status === true) {
          console.log(response);
          await updateFiles(response.data.data.user._id);
          this.loaded();
          this.show({
            text: "¡Miembro agregado exitosamente!",
            color: "success",
          });
          setTimeout(() => {
            this.filter();
          }, 2000);
        } else {
          this.loaded();
          this.show({
            text: "¡Ha ocurrido un problema al agregar el miembro, o ya existe!",
            color: "error",
          });
        }
      }
    },
    reloadInfo() {
      this.filter();
    },

    /**
     * Eliminar usuario cambiando su status a 'DELETED'
     * @param {Int} index
     */
    async deleteMemberUser(index) {
      try {
        if (this.membersNew[index].confirmDelete === false) {
          this.$set(this.membersNew, index, {
            ...this.membersNew[index],
            confirmDelete: true,
          });
        }
        if (
          this.membersNew[index].confirmDelete &&
          this.membersNew[index].authDelete
        ) {
          let id = this.membersNew[index]._id;
          const response = await this.deleteMember(id);
          if (response.status) {
            await this.filtrarMiembros();
            this.show({
              text: "Miembro eliminado exitosamente!",
              color: "success",
            });
          }
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        this.loaded();
      }
    },

    /**
     * Abrir ventana emergente para eliminar usuarios
     * @param {Int} index
     */
    openDeleteDialog(index) {
      /* https://v2.vuejs.org/v2/guide/reactivity.html*/
      this.$set(this.membersNew, index, {
        ...this.membersNew[index],

        deleteDialog: true,
      });
    },

    /** Función: cerrar ventana emergente */
    closeDeleteDialog(index) {
      this.$set(this.membersNew, index, {
        ...this.membersNew[index],
        deleteDialog: false,
        confirmDelete: false,
        authDelete: false,
      });
    },
  },
  destroyed() {
    this.clearmembersPaginated();
  },
  async mounted() {
    await this.fetchIndicators();
    await this.initializeFields();
    await this.filter();
    await this.fetchBranches();
  },
  //
  created() {
    console.log("test", this.user)

    if (
      this.user._id == "62b622c66a7441ae20159d7d" ||
      this.user._id == "6297b0fec7bf8b6f826313be"
    ) {
      this.items_validate_status = [
        { text: "Prospecto", value: "PROSPECTO" },
        { text: "Activo", value: "ACTIVE" },
        { text: "Inactivo", value: "INACTIVO" },
        { text: "Expirado", value: "EXPIRED" },
        { text: "Desarrollador", value: "DESARROLLADOR" },
      ];
    }

    if (this.$route.params.chapterId) {
      this.isChapter = true;
    }

    // this.filter();
  },
};
</script>

<style>
.text-yellow {
  color: orange;
  /* Cambia el color del texto a amarillo */
}

.theme--light.v-icon.text-yellow {
  font-size: 18px;
  color: orange;
}

.colorAccent1 {
  background-color: #fad0bf !important;
}

.colorAccent2 {
  background-color: #ffecb3 !important;
}

.colorAccent3 {
  background-color: #ffe0b2 !important;
}

.colorAccent4 {
  background-color: #cfd8dc !important;
}

.accent--text::before {
  color: #000000 !important;
}

.noShadow {
  box-shadow: none;
}

.tittlePermit {
  margin-top: 4%;
}

.bluetext {
  color: #141d30;
}

.textwhite {
  color: #ffffff !important;
}

.displayW {
  width: 100% !important;
  margin-top: 15px;
}

.displayW2 {
  width: 100% !important;
  margin-inline: 20px;
}

.textclass {
  color: #35404c;
  font-weight: bold;
}

.box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px !important;
}

.box>* {
  flex: 0 0 33.3333%;
}

/* iPhone */
@media (max-width: 875.98px) {
  b {
    font-size: 0.8em !important;
    white-space: normal;
  }

  .v-list-item__title {
    font-size: 0.8rem !important;
    white-space: normal;
  }

  .v-chip {
    font-size: 0.6rem !important;
    white-space: normal;
  }
}

.busqueda {
  padding-left: 20px;
  border: 0;
  border-color: transparent;
}

.search-prepend {
  height: 40px !important;
  border: 1px solid transparent !important;
}

.filters-card {
  max-height: 70vh;
  overflow-y: auto;
}

.higher-z-index {
  z-index: 1000;
  /* Adjust this value as needed */
}

.expansion-panel-width {
  max-width: 35vw;
  min-width: 35vw;
}

.total-text {
  color: #141d30;
}
</style>